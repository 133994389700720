import React from 'react';
import {useIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";
import SEO from "../components/seo";
import Layout from '../components/Layout';

// Assets

    // PAGES ZEGELING
    import IntroZegeling from '../images/work/work-intro-zegeling.jpg';
    import pageZegelingLogo from '../images/work/work-page-zegeling-markmedia-logo.jpg';
    import pageZegelingImage1 from '../images/work/work-page-zegeling-image-1.jpg';
    import pageZegelingImage2 from '../images/work/work-page-zegeling-image-2.jpg';
    import pageZegelingImage3 from '../images/work/work-page-zegeling-image-3.jpg';
    import pageZegelingImage4 from '../images/work/work-page-zegeling-image-4.jpg';
    import pageZegelingImage5 from '../images/work/work-page-zegeling-image-5.jpg';
    import pageZegelingImage6 from '../images/work/work-page-zegeling-image-6.jpg';
    import pageZegelingImage7 from '../images/work/work-page-zegeling-image-7.jpg';
    import pageZegelingImage8 from '../images/work/work-page-zegeling-image-8.jpg';
    import pageZegelingImage9 from '../images/work/work-page-zegeling-image-9.jpg';
    import pageZegelingImage10 from '../images/work/work-page-zegeling-image-10.jpg';

    // PAGE COOK
    import pageCookImage1 from '../images/work/work-page-cook-image-1.jpg';
    import pageCookImage2 from '../images/work/work-page-cook-image-2.jpg';
    import pageCookImage3 from '../images/work/work-page-cook-image-3.jpg';

    // PAGE TRAVEL
    import pageTravelImage1 from '../images/work/work-page-travel-image-1.jpg';
    import pageTravelImage2 from '../images/work/work-page-travel-image-2.jpg';
    import pageTravelImage3 from '../images/work/work-page-travel-image-3.jpg';

    // PAGE CHILDREN
    import pageChildrenImage1 from '../images/work/work-page-children-image-1.jpg';
    import pageChildrenImage2 from '../images/work/work-page-children-image-2.jpg';
    import pageChildrenImage3 from '../images/work/work-page-children-image-3.jpg';
    import pageChildrenImage4 from '../images/work/work-page-children-image-4.jpg';

    // PAGE ART
    import pageArtImage1 from '../images/work/work-page-art-image-1.jpg';
    import pageArtImage2 from '../images/work/work-page-art-image-2.jpg';
    import pageArtImage3 from '../images/work/work-page-art-image-3.jpg';

    // PAGE HARD COVER
    import pageHardImage1 from '../images/work/work-page-hard-image-1.jpg';
    import pageHardImage2 from '../images/work/work-page-hard-image-2.jpg';
    import pageHardImage3 from '../images/work/work-page-hard-image-3.jpg';
    import pageHardImage4 from '../images/work/work-page-hard-image-4.jpg';

    // PAGE HARD COVER
    import pageSpecialImage1 from '../images/work/work-page-special-image-1.jpg';
    import pageSpecialImage2 from '../images/work/work-page-special-image-2.jpg';
    import pageSpecialImage3 from '../images/work/work-page-special-image-3.jpg';
    import pageSpecialImage4 from '../images/work/work-page-special-image-4.jpg';
    import pageSpecialImage5 from '../images/work/work-page-special-image-5.jpg';
    import pageSpecialImage6 from '../images/work/work-page-special-image-6.jpg';
    import pageSpecialImage7 from '../images/work/work-page-special-image-7.jpg';
    import pageSpecialImage8 from '../images/work/work-page-special-image-8.jpg';
    import pageSpecialImage9 from '../images/work/work-page-special-image-9.jpg';
    import pageSpecialImage10 from '../images/work/work-page-special-image-10.jpg';

    // PAGE HARD COVER
    import pageComicImage1 from '../images/work/work-page-comic-image-1.jpg';
    import pageComicImage2 from '../images/work/work-page-comic-image-2.jpg';
    import pageComicImage3 from '../images/work/work-page-comic-image-3.jpg';
    import pageComicImage4 from '../images/work/work-page-comic-image-4.jpg';

    // PAGE SLIP CASES
    import pageSlipImage1 from '../images/work/work-page-slip-image-1.jpg';
    import pageSlipImage2 from '../images/work/work-page-slip-image-2.jpg';
    import pageSlipImage3 from '../images/work/work-page-slip-image-3.jpg';
    import pageSlipImage4 from '../images/work/work-page-slip-image-4.jpg';
    import pageSlipImage5 from '../images/work/work-page-slip-image-5.jpg';
    import pageSlipImage6 from '../images/work/work-page-slip-image-6.jpg';

    // PAGE SPECIAL EFFECTS
    import pageEffectsImage1 from '../images/work/work-page-effects-image-1.jpg';
    import pageEffectsImage2 from '../images/work/work-page-effects-image-2.jpg';
    import pageEffectsImage3 from '../images/work/work-page-effects-image-3.jpg';
    import pageEffectsImage4 from '../images/work/work-page-effects-image-4.jpg';


const Work = (props) => {

    const intl = useIntl();

    return (
        <Layout location={props.uri}>
            <div className="page__container-inner">

                <SEO 
                    title={intl.formatMessage({ id: "title.work" })}
                    description={intl.formatMessage({ id: "description.work" })}
                    lang={intl.locale}
                />

                <div className="page__sections-container">
                    <div className="page__section page__section--first work-intro">
                        
                        <article className="container">

                            <header className="work-intro__title-container">
                                <h1 className="work-intro__title"><FormattedHTMLMessage id="work.title" /></h1>
                            </header>

                            <div className="work-intro__main-content">

                                <div className="work-intro__text-container">

                                    <div className="work-intro__foreword">
                                        <span className="work-intro__foreword-label"><FormattedMessage id="work.intro-foreword-label" />:</span>
                                        <span className="work-intro__foreword-name"><FormattedMessage id="work.intro-foreword-name" />:</span>
                                    </div>

                                    <p className="work-intro__text-item"><FormattedMessage id="work.intro-p1" /></p>

                                    <p className="work-intro__text-item"><FormattedMessage id="work.intro-p2" /></p>

                                    <p className="work-intro__text-item"><FormattedMessage id="work.intro-p3" /></p>

                                </div>

                                <div className="work-intro__image-container">

                                    <img className="work-intro__image" src={IntroZegeling} alt="Mark Zegeling" />

                                </div>

                            </div>

                        </article>

                    </div>

                    <div className="page__section work-main">
                        
                        <article className="work-main__page work-main__page--multiple">

                            <header className="work-main__page-header">
                                <h2 className="work-main__page-title"><FormattedHTMLMessage id="work.main-title-zegeling" /></h2>
                                <img className="work-main__page-logo" src={pageZegelingLogo} alt="MarkMedia&Art" />
                            </header>

                            <div className="work-main__gallery work-main__gallery--row work-main__gallery--2-1">

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageZegelingImage1} alt="Mark Zegeling Books" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageZegelingImage2} alt="Mark Zegeling Books" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--one-image">
                                    <div className="work-main__image-container">
                                        <img src={pageZegelingImage3} alt="Mark Zegeling Books" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page work-main__page--multiple">

                            <div className="work-main__gallery work-main__gallery--row work-main__gallery--2-1">

                                <div className="work-main__gallery-section work-main__gallery-section--one-image">
                                    <div className="work-main__image-container">
                                        <img src={pageZegelingImage4} alt="Mark Zegeling Books" />
                                    </div>
                                </div>
                                
                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageZegelingImage5} alt="Mark Zegeling Books" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageZegelingImage6} alt="Mark Zegeling Books" />
                                    </div>

                                </div>

                            </div>

                        </article>

                        <article className="work-main__page work-main__page--multiple work-main__page--multiple-last">

                            <div className="work-main__gallery work-main__gallery--row">

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageZegelingImage7} alt="Mark Zegeling Books" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageZegelingImage8} alt="Mark Zegeling Books" />
                                    </div>

                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageZegelingImage9} alt="Mark Zegeling Books" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageZegelingImage10} alt="Mark Zegeling Books" />
                                    </div>

                                </div>

                                
                                
                            </div>

                        </article>

                        <article className="work-main__page">

                            <header className="work-main__page-header">
                                <div className="work-main__page-title-container">
                                    <h2 className="work-main__page-title"><FormattedHTMLMessage id="work.main-title-cook" /></h2>
                                </div>
                            </header>

                            <div className="work-main__gallery work-main__gallery--row work-main__gallery--2-1">

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageCookImage1} alt="Cook Books" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageCookImage2} alt="Cook Books" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--one-image">
                                    <div className="work-main__image-container">
                                        <img src={pageCookImage3} alt="Cook Books" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page">

                            <header className="work-main__page-header">
                                <div className="work-main__page-title-container">
                                    <h2 className="work-main__page-title"><FormattedHTMLMessage id="work.main-title-travel" /></h2>
                                </div>
                            </header>

                            <div className="work-main__gallery work-main__gallery--column">

                                <div className="work-main__gallery-section work-main__gallery-section--one-image">
                                    <div className="work-main__image-container">
                                        <img src={pageTravelImage1} alt="Travel Guides" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageTravelImage2} alt="Travel Guides" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageTravelImage3} alt="Travel Guides" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page">

                            <header className="work-main__page-header">
                                <div className="work-main__page-title-container">
                                    <h2 className="work-main__page-title"><FormattedHTMLMessage id="work.main-title-children" /></h2>
                                </div>
                            </header>

                            <div className="work-main__gallery work-main__gallery--row">

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageChildrenImage1} alt="Childrens Books" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageChildrenImage2} alt="Childrens Books" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageChildrenImage3} alt="Childrens Books" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageChildrenImage4} alt="Childrens Books" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page work-main__page--multiple">

                            <header className="work-main__page-header">
                                <div className="work-main__page-title-container">
                                    <h2 className="work-main__page-title"><FormattedHTMLMessage id="work.main-title-art" /></h2>
                                </div>
                            </header>

                            <div className="work-main__gallery work-main__gallery--row">

                                <div className="work-main__gallery-section work-main__gallery-section--one-image">
                                    <div className="work-main__image-container">
                                        <img src={pageArtImage1} alt="Art Books" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page work-main__page--multiple">

                            <div className="work-main__gallery work-main__gallery--row">

                                <div className="work-main__gallery-section work-main__gallery-section--one-image">
                                    <div className="work-main__image-container">
                                        <img src={pageArtImage2} alt="Art Books" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page work-main__page--multiple work-main__page--multiple-last">

                            <div className="work-main__gallery work-main__gallery--row">

                                <div className="work-main__gallery-section work-main__gallery-section--one-image">
                                    <div className="work-main__image-container">
                                        <img src={pageArtImage3} alt="Art Books" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page">

                            <header className="work-main__page-header">
                                <div className="work-main__page-title-container">
                                    <h2 className="work-main__page-title"><FormattedHTMLMessage id="work.main-title-hard" /></h2>
                                </div>
                            </header>

                            <div className="work-main__gallery work-main__gallery--row">

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageHardImage1} alt="Hard Cover" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageHardImage2} alt="Hard Cover" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageHardImage3} alt="Hard Cover" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageHardImage4} alt="Hard Cover" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page work-main__page--multiple">

                            <header className="work-main__page-header">
                                <div className="work-main__page-title-container">
                                    <h2 className="work-main__page-title"><FormattedHTMLMessage id="work.main-title-special" /></h2>
                                </div>
                            </header>

                            <div className="work-main__gallery work-main__gallery--row">

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageSpecialImage1} alt="Special Covers" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageSpecialImage2} alt="Special Covers" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageSpecialImage3} alt="Special Covers" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageSpecialImage4} alt="Special Covers" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageSpecialImage5} alt="Special Covers" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageSpecialImage6} alt="Special Covers" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page work-main__page--multiple work-main__page--multiple-last">

                            <div className="work-main__gallery work-main__gallery--row">

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageSpecialImage7} alt="Special Covers" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageSpecialImage8} alt="Special Covers" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageSpecialImage9} alt="Special Covers" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageSpecialImage10} alt="Special Covers" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page">

                            <header className="work-main__page-header">
                                <div className="work-main__page-title-container">
                                    <h2 className="work-main__page-title"><FormattedHTMLMessage id="work.main-title-comic" /></h2>
                                </div>
                            </header>

                            <div className="work-main__gallery work-main__gallery--column">

                                <div className="work-main__gallery-section work-main__gallery-section--two-images work-main__gallery-section--1-2">
                                    <div className="work-main__image-container">
                                        <img src={pageComicImage1} alt="Comic Books" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageComicImage2} alt="Comic Books" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageComicImage3} alt="Comic Books" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageComicImage4} alt="Comic Books" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page">

                            <header className="work-main__page-header">
                                <div className="work-main__page-title-container">
                                    <h2 className="work-main__page-title"><FormattedHTMLMessage id="work.main-title-slip" /></h2>
                                </div>
                            </header>

                            <div className="work-main__gallery work-main__gallery--column">

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageSlipImage1} alt="Slip Cases" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageSlipImage2} alt="Slip Cases" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageSlipImage3} alt="Slip Cases" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--two-images">
                                    <div className="work-main__image-container">
                                        <img src={pageSlipImage4} alt="Slip Cases" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageSlipImage5} alt="Slip Cases" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageSlipImage6} alt="Slip Cases" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                        <article className="work-main__page">

                            <header className="work-main__page-header">
                                <div className="work-main__page-title-container">
                                    <h2 className="work-main__page-title"><FormattedHTMLMessage id="work.main-title-effects" /></h2>
                                </div>
                            </header>

                            <div className="work-main__gallery work-main__gallery--column">

                                <div className="work-main__gallery-section work-main__gallery-section--two-images work-main__gallery-section--1-2">
                                    <div className="work-main__image-container">
                                        <img src={pageEffectsImage1} alt="Special Effects" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageEffectsImage2} alt="Special Effects" />
                                    </div>
                                </div>

                                <div className="work-main__gallery-section work-main__gallery-section--two-images work-main__gallery-section--1-2">
                                    <div className="work-main__image-container">
                                        <img src={pageEffectsImage3} alt="Special Effects" />
                                    </div>
                                    <div className="work-main__image-container">
                                        <img src={pageEffectsImage4} alt="Special Effects" />
                                    </div>
                                </div>
                                
                            </div>

                        </article>

                    </div>
                </div>
            </div>
        </Layout>
    );

}

export default Work;
